<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Mes a consultar"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            color="primary"
            :max="new Date().toISOString().substr(0, 7)"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancel</v-btn>
            <v-btn text @click="$refs.menu.save(date)"> OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-chart
          ref="chartDiaria"
          theme="walden"
          class="ma-auto"
          style="width: 100%"
          :options="chartDiaria"
          autoresize
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="items">
          <template v-slot:item.total="{ item }">
            {{ item.total | toCurrency }}
          </template>
          <template v-slot:item.ordered_at="{ item }">
            {{ item.ordered_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:item.delivery_date="{ item }">
            {{ item.delivery_date | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="primary" @click="getOrder(item.id)"
              >mdi-open-in-new
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="orderDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="order">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Orden #{{ order.order_number }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Cliente</v-list-item-title>
                  <v-list-item-subtitle>{{
                    order.client.name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Total</v-list-item-title>
                  <v-list-item-subtitle>{{
                    order.total | toCurrency
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="3">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Fecha de entrega</v-list-item-title>
                  <v-list-item-subtitle>{{
                    order.delivery_date | moment("L")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="3">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Fecha de creación</v-list-item-title>
                  <v-list-item-subtitle>{{
                    order.ordered_at | moment("L")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersDetail"
                :items="order.order_details"
              >
                <template v-slot:item.unit_price="{ item }">
                  {{ item.unit_price | toCurrency }}
                </template>
                <template v-slot:item.total="{ item }">
                  {{ (item.quantity * item.unit_price) | toCurrency }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import {TweenMax} from "gsap";
import ECharts from "vue-echarts";
// import ECharts modules manually to reduce bundle size
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
  name: "Diaria",
  components: {
    "v-chart": ECharts,
  },
  data: () => ({
    headersDetail: [
      {
        text: "Producto",
        value: "product.name",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Precio Unitario",
        value: "unit_price",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    headers: [
      {
        text: "#",
        value: "order_number",
      },
      {
        text: "Cliente",
        value: "client.name",
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Creada",
        value: "ordered_at",
      },
      {
        text: "Entregada",
        value: "delivery_date",
      },
      {
        text: "-",
        value: "action",
      },
    ],
    items: [],
    clientes: [
      "AT HOME",
      "AMERICAN FURNITURE",
      "CHEYENNE",
      "STEVE SILVER COMPANY",
      "FURNITURE OUTLETS USA",
    ],
    selectedClients: [
      "AT HOME",
      "AMERICAN FURNITURE",
      "CHEYENNE",
      "STEVE SILVER COMPANY",
      "FURNITURE OUTLETS USA",
    ],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    chartDiaria: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          let total = params.reduce((tot, b) => tot + b.value, 0);

          for (let i = 0; i < params.length; i++) {
            let perc = ((params[i].value / total) * 100).toFixed(2);
            perc = isNaN(perc) ? 0 : perc;

            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              params[i].value +
              " ( " +
              perc +
              "% )"; // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          return output;
        },
      },
      legend: {
        data: [],
      },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [],
    },
    order: null,
    orderDialog: false,
  }),
  computed: {
    allClients() {
      return this.selectedClients.length === this.clientes.length;
    },
    algunosClientes() {
      return this.selectedClients.length > 0 && !this.allClients;
    },
    icon() {
      if (this.allClients) return "mdi-close-box";
      if (this.algunosClientes) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    date() {
      this.getOrderReport();
    },
  },
  mounted() {
    this.$refs.chartDiaria.chart.showLoading("default", {
      text: "Cargando Datos",
    });
    this.getOrderReport();
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allClients) {
          this.selectedClients = [];
        } else {
          this.selectedClients = this.clientes.slice();
        }
      });
    },
    getOrderReport() {
      this.$refs.chartDiaria.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$http
        .post("orders/getOrdersReport", {
          date: this.date,
        })
        .then((res) => {
          // //(res);
          // this.clientes = res.data.data;
          this.items = res.data.orders;
          let me = this;
          this.$nextTick(() => {
            this.$refs.chartDiaria.chart.setOption(
              {
                tooltip: {
                  confine: true,
                  trigger: "axis",
                  axisPointer: {
                    type: "shadow",
                  },
                  formatter: function (params) {
                    let output = "<b>" + params[0].name + "</b><br/>";
                    let total = params.reduce((tot, b) => tot + b.value, 0);

                    for (let i = 0; i < params.length; i++) {
                      let perc = ((params[i].value / total) * 100).toFixed(2);
                      perc = isNaN(perc) ? 0 : perc;

                      output +=
                        params[i].marker +
                        params[i].seriesName +
                        ": " +
                        me.$options.filters.toCurrency(params[i].value) +
                        " ( " +
                        perc +
                        "% )"; // : every 2nth

                      if (i !== params.length - 1) {
                        // Append a <br/> tag if not last in loop
                        output += "<br/>";
                      }
                    }
                    return output;
                  },
                },
                legend: {
                  data: res.data.categories,
                },
                xAxis: {
                  type: "category",
                  data: res.data.days,
                },
                yAxis: {
                  type: "value",
                },
                series: res.data.series,
              },
              true
            );
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$refs.chartDiaria.chart.hideLoading();
        });
    },
    getOrder(orderId) {
      this.$store.dispatch("working", true);
      this.$http
        .post("orders/getOrder", {
          orderId: orderId,
        })
        .then((res) => {
          // //(res);
          // this.clientes = res.data.data;
          this.order = res.data;
          this.orderDialog = true;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
    closeDialog() {
      this.orderDialog = false;
      this.order = null;
    },
  },
};
</script>

<style scoped></style>
