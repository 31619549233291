var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mes a consultar","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","color":"primary","max":new Date().toISOString().substr(0, 7)},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-chart',{ref:"chartDiaria",staticClass:"ma-auto",staticStyle:{"width":"100%"},attrs:{"theme":"walden","options":_vm.chartDiaria,"autoresize":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.total))+" ")]}},{key:"item.ordered_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.ordered_at,"DD/MM/YYYY"))+" ")]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.delivery_date,"DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getOrder(item.id)}}},[_vm._v("mdi-open-in-new ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.orderDialog),callback:function ($$v) {_vm.orderDialog=$$v},expression:"orderDialog"}},[(_vm.order)?_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Orden #"+_vm._s(_vm.order.order_number))])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cliente")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.order.client.name))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.order.total)))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fecha de entrega")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("moment")(_vm.order.delivery_date,"L")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fecha de creación")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("moment")(_vm.order.ordered_at,"L")))])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersDetail,"items":_vm.order.order_details},scopedSlots:_vm._u([{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.unit_price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")((item.quantity * item.unit_price)))+" ")]}}],null,false,1409055508)})],1)],1),_c('v-row')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }